<template>
  <v-container>
    <component :is="getComponent" />
  </v-container>
</template>

<script>
import { ref, computed, provide, onMounted } from "@vue/composition-api";

export default {
  setup(_, { root }) {
    const store = root.$store;

    const getComponent = computed(() => {
      if (!store.getters.getCurrentReport)
        return () => import("./reports/ReportList");
      else return () => import("./reports/Report");
    });

    const isEditMode = ref(false);
    provide("isEditMode", isEditMode);

    onMounted(() => localStorage.setItem("lastTab", "Reports"));

    return {
      getComponent,
    };
  },
};
</script>
